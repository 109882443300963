import React from 'react'
import Col from 'react-bootstrap/Col';
import {Helmet} from "react-helmet";

const Tshirt = () => {
  return (
    <>  
      <Helmet>
        <meta charSet="utf-8" />
        <title>Custom T-Shirts | T-Shirts Printing in India | Printingbrothers.com</title>
        <meta name="description" content="Custom T-Shirts | T-Shirts Printing in India | Printingbrothers.com" />
        <meta property="og:title" content="Custom T-Shirts | T-Shirts Printing in India | Printingbrothers.com" />
        <meta property="og:description" content="T-shirts are a staple in casual wear, known for their comfort and versatility." />
        <meta property="og:image" content="https://printingbrother.com/images/int-cat-images/polyster_pb.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="http://printingbrother.com/tshirts" />
      </Helmet>
    

      <nav aria-label="breadcrumb" className='container'>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">T-Shirts</li>
        </ol>
      </nav>
      <div className='header_img'><a href="#/contactus"><img src="http://printingbrother.com/images/header_img/tshirt_header.jpg"></img></a>
        </div>
      <div class="container main-div">
    
      <div className='row'>
      <div class="col-lg-12 col-md-12 mb-4">
		<div className=''>
			<div class="main_heading">
			    <h1>T-SHIRTS</h1>	
			</div>
          <p>Get high-quality custom T-shirt printing for any occasion—whether it's a single shirt or a bulk order. From collared tees to printed sweatshirts, we make it easy to add your design, logo, or text for a personalized look. Our vibrant, durable prints are perfect for fashion, events, or gifts.</p>
          <p>Choose from a range of styles like custom T-shirts, event tees, and sweatshirts, all tailored to your needs. With both local and online T-shirt printing options, you can enjoy a quick, hassle-free experience.</p>
	</div></div>
      </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="main_heading">
              <h4 className=''>Round Neck</h4>
          </div>
        </div>
      <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                <div className='image_wraper img-hover-zoom--slowmo border'>
                  <div> 
                    <img src='http://printingbrother.com/images/int-cat-images/cotton_pb.jpg' alt=''/>
                  </div>
                </div>
                <div className='caption'>
                  <h5>Cotton Classic T-shirts</h5>
                  {/* <p>{discription}</p> */}
                  {/* <a class="" href={link}>View All</a> */}
                </div>
            </div>   
          </Col>
          <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/polyster_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    <h5>Polyester T-Shirt</h5>
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
            </div>       
          </Col>
          <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/dryfit_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    <h5>Sarina/Dri-Fit T-shirts</h5>
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
              </div>       
          </Col>
          <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/oversized_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    <h5>Oversized T-Shirt</h5>
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
              </div>       
          </Col>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
      <div class="main_heading">
              <h4 className=''>Polo T-Shirts</h4>
          </div>
      </div>
      <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                <div className='image_wraper img-hover-zoom--slowmo border'>
                  <div> 
                    <img src='http://printingbrother.com/images/int-cat-images/collar_cotton_pb.jpg' alt=''/>
                  </div>
                </div>
                <div className='caption'>
                  <h5>Collar Cotton T-shirts</h5>
                  {/* <p>{discription}</p> */}
                  {/* <a class="" href={link}>View All</a> */}
                </div>
            </div>   
          </Col>
          <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                <div className='image_wraper img-hover-zoom--slowmo border'>
                  <div> 
                    <img src='http://printingbrother.com/images/int-cat-images/collar_dryfit_pb.jpg' alt=''/>
                  </div>
                </div>
                <div className='caption'>
                  <h5>Collar Dri-Fit / Sarina T-shirts</h5>
                  {/* <p>{discription}</p> */}
                  {/* <a class="" href={link}>View All</a> */}
                </div>
            </div>        
          </Col>
          <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                <div className='image_wraper img-hover-zoom--slowmo border'>
                  <div> 
                    <img src='http://printingbrother.com/images/int-cat-images/collar_dryfit_pb.jpg' alt=''/>
                  </div>
                </div>
                <div className='caption'>
                  <h5>Collar Dri-Fit / Sarina T-shirts</h5>
                  {/* <p>{discription}</p> */}
                  {/* <a class="" href={link}>View All</a> */}
                </div>
            </div>        
          </Col>
          <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                <div className='image_wraper img-hover-zoom--slowmo border'>
                  <div> 
                    <img src='http://printingbrother.com/images/int-cat-images/collar_dryfit_pb.jpg' alt=''/>
                  </div>
                </div>
                <div className='caption'>
                  <h5>Collar Dri-Fit / Sarina T-shirts</h5>
                  {/* <p>{discription}</p> */}
                  {/* <a class="" href={link}>View All</a> */}
                </div>
            </div>        
          </Col>
    </div>
    </div>
    </>
  )
}

export default Tshirt