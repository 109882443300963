import React, { useState } from 'react'
import MugApi from '../json/mugApi';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Mugs } from './allinternal';

const Mug = () => {

const [MugApiData, setMugApiData] = useState(MugApi);
    return (
      <>
        <nav aria-label="breadcrumb" className='container'>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Pen & Diaries</li>
        </ol>
      </nav>
      <div className='header_img'><a href="#/contactus"><img src="http://printingbrother.com/images/header_img/bottle_header.jpg"></img></a>
        </div>

       <Container className='main-div'>
            <Row className="">
              <div class="col-lg-12">
              <div className='row'>
    <div class="col-lg-12 col-md-12 mb-4">
    <div className='bg-light p-4'>
    <div class="main_heading">
    <h1>Bottles & Mugs</h1>
    </div>
    <p>Custom Bottles and Mugs for Branding Success</p>
    <p className='mb-0'>Stay refreshed and stylish with custom bottles and mugs from Printing Brother. Perfect for corporate gifts and promotional items, our bottles and mugs can be personalized with your logo or design. Choose from a variety of styles and finishes to showcase your brand.</p>
    </div></div>
    </div>
                
                <div class="row">
                  <Mugs MugApiData={MugApiData} />
                </div>
              </div>
            </Row>
        </Container>
      </>
    )
  }

export default Mug