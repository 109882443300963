// import React from 'react'

import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../pb_logo.png';
import Button from 'react-bootstrap/Button';
import { BsWhatsapp, BsLinkedin, BsEnvelope, BsFacebook, BsInstagram} from "react-icons/bs";
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdFileDownload } from "react-icons/md";


function Header () {
  return (
    <>
    <Button variant="success" href="https://wa.me/919717793856" className='wp_chat_button text-white'><BsWhatsapp /> <br/></Button>
    {/* <div className="alert alert-sm alert-danger fw-bold text-center p-1 mb-0 rounded-0" role="alert"> Our Website is Under Maintenance ! </div> */}

    <Container>
      <Row className="justify-content-center align-items-center">
        <Col xl="4" lg="3" md="10" sm="10" xs="10">
          <a href="/" className='main_logo'><img src={logo}  alt="img"/></a>
        </Col>
        <Col xl="6" lg="6" md="6" sm="7" className='text-end d-none d-lg-block'>
          <ul className='list-unstyled contact-info mb-1'>
            <li>
                <a href="mailto:printingbrothers@gmail.com"><BsEnvelope /> printingbrothers2023@gmail.com</a>
            </li>
          </ul>
          <ul className='list-unstyled mb-0 nav_icon'>
            <li>Fllow Us On</li>
            <li>
                <a href="https://www.instagram.com/printing.brothers/" target='_blank'><BsInstagram /></a>
            </li>
            <li>
                <a href="https://www.facebook.com/profile.php?id=61551030107126" target='_blank'><BsFacebook /></a>
            </li>
            <li>
              <a href='https://www.linkedin.com/in/printing-brothers-064298292/' target='_blank'><BsLinkedin /></a>
            </li>
          </ul>
        </Col>
        <Col xl="2" lg="3" className='text-end d-none d-lg-block'>
          <a href="" className='btn btn-outline-primary my-2 text-start d-flex align-items-center download_broucher h-100'><MdFileDownload /><span className='d-block'>Download Brochure</span></a>
        </Col>
        <Col lg="2" md="2" sm="2" xs="2" className='d-block d-lg-none'>
            {[false].map((expand) => (
            <Navbar key={expand} expand={expand} className="bg-white fluid justify-content-end">
                {/* <Navbar.Brand href="#">Navbar Offcanvas</Navbar.Brand> */}
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas 
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end" >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                      <a href="/" className='main_logo'><img src={logo}  alt="img"/></a>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                  
              <ul className="navbar-nav me-auto mb-0 mb-lg-0">
                <li className="nav-item"><a className="nav-link" href="#/component/Internal_Pages/Tshirt">T-Shirts</a></li>
                <li className="nav-item"><a className="nav-link" href="#/component/Internal_Pages/Hoodies">Sweatshirts & Hoodies</a></li>
                <li className="nav-item"><a className="nav-link" href="#/component/Internal_Pages/Caps">Caps</a></li>
                <li className="nav-item"><a className="nav-link" href="#/component/Internal_Pages/Lanyards">Lanyards</a></li>
                {/* <li className="nav-item"><a className="nav-link" href="#/component/Internal_Pages/Pens">Pens</a></li> */}
                <li className="nav-item"><a className="nav-link" href="#/component/Internal_Pages/Coasters">Coasters</a></li>
                <li className="nav-item"><a className="nav-link" href="#/component/Internal_Pages/KeyChains">KeyChains</a></li>
                <li className="nav-item"><a className="nav-link" href="#/component/Internal_Pages/Mugs">Bottle & Mug</a></li>
                <li className="nav-item"><a className="nav-link" href="#/component/Internal_Pages/Bags">Bags</a></li>
                <li className="nav-item"><a className="nav-link" href="#/component/Internal_Pages/Diaries">Diarie & Pen</a></li>
              </ul>
                  
              <Form className="border-top pt-2 mt-3">
                  <ul className='list-unstyled contact-info mb-1'>
                    <li><a href=""><BsEnvelope /> printingbrothers2023@gmail.com</a></li>
                  </ul>
          <ul className='list-unstyled mb-0 nav_icon'>
            <li>Fllow Us On</li>
            <li>
                <a href="https://www.instagram.com/printing.brothers/" target='_blank'><BsInstagram /></a>
            </li>
            <li>
                <a href="https://www.facebook.com/profile.php?id=61551030107126" target='_blank'><BsFacebook /></a>
            </li>
            <li>
              <a href='https://www.linkedin.com/in/printing-brothers-064298292/' target='_blank'><BsLinkedin /></a>
            </li>
          </ul>
                    </Form>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>   
            </Navbar>
          ))}
        </Col>
      </Row>
    </Container>
    </>
  )
}


document.addEventListener('DOMContentLoaded', function () {
  const links = document.querySelectorAll('.nav-link');

  links.forEach(link => {
      link.addEventListener('click', function (event) {
          if (window.innerWidth === 991) {
              event.preventDefault();  // Prevent the default action to allow the refresh
              window.location.href = this.getAttribute('href'); // Redirect to the link
          }
      });
  });
});

export default Header