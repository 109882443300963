import React from 'react'
import Col from 'react-bootstrap/Col';

const Bags = () => {
  return (
    
    <>    
     <nav aria-label="breadcrumb" className='container'>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Bag</li>
        </ol>
      </nav>
      <div className='header_img'><a href="#/contactus"><img src="http://printingbrother.com/images/header_img/bag_header.jpg"></img></a>
        </div>
    
    <div class="container main-div">
      <div className='row'>
      <div class="col-lg-12 col-md-12 mb-4">
		<div className='bg-light p-4'>
			<div class="main_heading">
			    <h1>Bags</h1>	
			</div>
            <p className='mb-0'>Discover our premium range of bags designed to suit every need and style! Whether you're looking for the eco-friendly charm of our jute bags, the everyday practicality of our normal bags, or the sturdy elegance of our canvas bags, we've got you covered. Our office bags are crafted to keep your professional essentials organized and accessible, while our laptop bags offer top-notch protection for your tech. Each bag is made with high-quality materials, ensuring durability and style. Perfect for corporate gifting or personal use, these bags combine functionality with a touch of sophistication. Choose from our diverse collection to find the perfect bag for any occasion!</p>
	</div></div>
      </div>
        <div class="row">
            {/* <div class="col-lg-12 col-md-12">
            <div class="main_heading">
                <h2>Round Neck t-shirts</h2>
            </div>
            </div> */}
        <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/bag1_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                </div>   
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/bag2_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                </div>   
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/bag3_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                </div>   
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/bag4_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                </div>   
            </Col>

            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/bag5_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                </div>   
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/bag6_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                </div>   
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/bag7_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                </div>   
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/bag8_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                </div>   
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/bag9_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                </div>   
            </Col>
        </div>
    </div>
    </>
  )
}

export default Bags