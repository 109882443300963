import React from 'react'
import Col from 'react-bootstrap/Col';

const KeyChains = () => {
  return (
    <>  

    <nav aria-label="breadcrumb" className='container'>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Keychains</li>
        </ol>
      </nav>
      <div className='header_img'><a href="#/contactus"><img src="http://printingbrother.com/images/header_img/keychains_header.jpg"></img></a>
        </div> 

    <div class="container main-div">
      <div className='row'>
      <div class="col-lg-12 col-md-12 mb-4">
		<div className='bg-light p-4'>
			<div class="main_heading">
			    <h1>Kaychains</h1>	
			</div>
            <p>Personalized Keychains for Effective Promotion</p>
            <p className='mb-0'>Promote your brand with custom keychains from Printing Brother. Ideal for corporate giveaways and events, our keychains can be personalized with your logo or design. Available in a range of styles and materials, they provide a practical and memorable branding solution.</p>
	</div></div>
      </div>
        <div class="row">
            {/* <div class="col-lg-12 col-md-12">
            <div class="main_heading">
                <h2>Round Neck t-shirts</h2>	
            </div>
            </div> */}
        <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/keychain1_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                    <div className='caption'>
                    {/* <h5>Cotton Classic T-shirts</h5> */}
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                    </div>
                </div>   
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/keychain2_printingbrothers.jpg' alt=''/>
                        </div>
                    </div>
                </div>       
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/keychain3_printingbrothers.jpg' alt=''/>
                        </div>
                    </div>
                </div>       
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/keychain4_printingbrothers.jpg' alt=''/>
                        </div>
                    </div>
                </div>       
            </Col>
        </div>
    </div>
    </>
  )
}

export default KeyChains