import React from 'react'
import Col from 'react-bootstrap/Col';

const Coasters = () => {
  return (
    <>  
     <nav aria-label="breadcrumb" className='container'>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Coasters</li>
        </ol>
      </nav>
      <div className='header_img'><a href="#/contactus"><img src="http://printingbrother.com/images/header_img/caosters_header.jpg"></img></a>
        </div>

     <div class="container main-div">
      <div className='row'>
      <div class="col-lg-12 col-md-12 mb-4">
		<div className='bg-light p-4'>
			<div class="main_heading">
			    <h1>Coasters</h1>	
			</div>
            <p>Custom Coasters to Enhance Your Brand’s Presence</p>
            <p className='mb-0'>Elevate your branding with personalized coasters from Printing Brother. Ideal for corporate gifts and promotional events, our coasters can be customized with your logo or design. Made from high-quality materials, they offer both practicality and a stylish touch to any setting.</p>
	</div></div>
      </div>
        <div class="row">
        <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/coaster1_printingbrothers.jpg' alt=''/>
                    </div>
                    </div>
                </div>   
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/coaster2_printingbrothers.jpg' alt=''/>
                        </div>
                    </div>
                </div>       
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/coaster3_printingbrothers.jpg' alt=''/>
                        </div>
                    </div>
                </div>       
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/coaster4_printingbrothers.jpg' alt=''/>
                        </div>
                    </div>
                </div>       
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/coaster5_printingbrothers.jpg' alt=''/>
                        </div>
                    </div>
                </div>       
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/coaster6_printingbrothers.jpg' alt=''/>
                        </div>
                    </div>
                </div>       
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                        <img src='http://printingbrother.com/images/int-cat-images/coaster7_printingbrothers.jpg' alt=''/>
                        </div>
                    </div>
                </div>       
            </Col>
        </div>
    </div>
    </>
  )
}

export default Coasters