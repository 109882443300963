import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';



const HomeNav = () => {
  return (
    <>
     <Navbar collapseOnSelect expand="lg" className="d-lg-block d-none">
      <Container>
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            {/* <Nav className="me-auto"> */}
              <ul className="navbar-nav me-auto mb-0 mb-lg-0">
                {/* <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li> */}
                <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/Tshirt">T-Shirts</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/Hoodies">Sweatshirts & Hoodies</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/Caps">Caps</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/Lanyards">Lanyards & Badges</Link></li>
                {/* <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/Pens">Pens</Link></li> */}
                {/* <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/Drinkware">Drinkware</Link></li> */}
                <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/Coasters">Coasters</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/KeyChains">KeyChains</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/Mugs">Bottles & Mugs</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/Bags">Bags</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/component/Internal_Pages/Diaries">Diary & Pen</Link></li>
                {/* <li className="nav-item"><Link className="nav-link" to="/OurGallery"><span className=''>Our Gallery</span></Link></li>
                <li className="nav-item"><Link className="nav-link" to="/contactus">Contact Us</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/aboutus">About Us</Link></li> */}
              </ul>
              <ul className="navbar-nav ms-auto mb-0 mb-lg-0">
                {/* <li className="nav-item"><Link className="nav-link" to="/OurGallery"><span className=''>Our Gallery</span></Link></li> */}
                {/* <li className="nav-item"><Link className="nav-link" to="/aboutus">About Us</Link></li> */}
                <li className="nav-item"><Link className="nav-link" to="/contactus">Contact Us</Link></li>
              </ul>
            {/* </Nav> */}
          </Navbar.Collapse>
    </Container>
  </Navbar>
   
    </>
  );
}

export default HomeNav;

